























































import Ueditor from "@/components/Ueditor/index.vue";
import { Component, Ref, Vue } from "vue-property-decorator";
import api from "@/api";
import {
  CmsCategoryType,
  AttachmentHostType,
  MessageDto,
  MessageCreateOrUpdateDto,
} from "@/api/appService";
import { ElForm } from "element-ui/types/form";
import AbSelect from "@/components/AbSelect/index.vue";
import MultipleUploadFile from "@/components/MultipleUploadFile/index.vue";

@Component({
  components: {
    AbSelect,
    Ueditor,
    MultipleUploadFile,
  },
})
export default class CreateMessage extends Vue {
  @Ref() readonly dataForm!: ElForm;
  form: MessageCreateOrUpdateDto = {
    title: undefined,
    content: undefined,
    id: 0,
  };
  typeList: any = [];
  dataId?: number;
  btnDisabled = false;

  get hostTypeFile() {
    return AttachmentHostType.Message;
  }

  created() {
    this.dataId = Number(this.$route.params.id);
    if (this.dataId) {
      api.message.get({ id: this.dataId }).then((res) => {
        this.form = { ...res };
      });
    }
    this.fetchEnumType();
  }

  async fetchEnumType() {
    await api.enumService
      .getValues({
        typeName: "MessageType",
      })
      .then((res) => {
        this.typeList = res;
      });
  }

  async save() {
    this.btnDisabled = true;
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (this.form!.id) {
          await api.message.update({
            body: this.form,
          });
        } else {
          await api.message.create({
            body: this.form,
          });
        }
        this.$router.back();
        this.$message.success("更新成功");
      } else {
        this.$message.error("请检查表单");
        this.btnDisabled = false;
      }
    });
  }

  cancel() {
    this.$router.back();
    // (this.$refs.dataForm as any).resetFields();
    // this.$emit("input", false);
  }

  roleRule = {
    title: [
      {
        required: true,
        message: "消息标题必填",
        trigger: "blur",
      },
    ],
    messageType: [
      {
        required: true,
        message: "消息类型必填",
        trigger: "blur",
      },
    ],
    content: [
      {
        required: true,
        message: "消息内容必填",
        trigger: "blur",
      },
    ],
  };
}
